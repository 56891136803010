function Navigation({className}) {
    return (
        <>
            <div className={`dilimatrix-header-main-menu ${className || ""}`}>
                <ul>
                    {/* <li>
            <Link to="/">Home</Link>
          </li> */}
                    {/* <li>
            <Link to="#">About Us</Link>
          </li> */}
                    {/* <li>
            <Link to="#">Services</Link>
          </li>
          <li>
            <Link to="#">Portfolio</Link>
          </li> */}
                    {/* <li>
            <Link to="/contact">Contact Us</Link>
          </li> */}
                </ul>
            </div>
        </>
    );
}

export default Navigation;
