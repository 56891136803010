import React, {useEffect} from "react";
// import logo2 from "../../../../assets/images/logos/logo-two.png";
import logo from "../../../../assets/images/logos/dilimatrix-banner-white.png";
import StickyMenu from "../../../../lib/StickyMenu";

function HeaderHomeOne() {
    useEffect(() => {
        StickyMenu(".main-header");
    });

    return (
        <>
            {/* <MobileHeaderCom logo={logo2} /> */}
            <header className="main-header header-one">
                {/* <div className="header-top bg-lighter py-10">
          <div className="top-left">
            <ul>
              <li>
                Call Us: <a href="callto:548978478">548978478</a>
              </li>
              <li>
                Email us: <a href="mailto:demo@example.com">demo@example.com</a>
              </li>
              <li>Our address: 45 Dream street Austria</li>
            </ul>
          </div>
          <div className="top-right">
            <div className="office-time">
              <i className="far fa-clock"></i>
              <span>08:00 am - 06:00 pm</span>
            </div>
            <div className="social-style-one">
              <a href="http://facebook.com">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="http://twitter.com">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com/">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.pinterest.com/">
                <i className="fab fa-pinterest-p"></i>
              </a>
            </div>
          </div>
        </div> */}
                <div className="header-upper bg-white">
                    <div
                        className="container-fluid clearfix"
                        styles={{backgroundColor: "#bbe7ff"}}
                    >
                        <div className="header-inner-custom d-flex align-items-center">
                            <div
                                className="nav-outer logo-outer my-header py-10 px-85"
                                style={{width: "30%"}}
                            >
                                <div className="logo">
                                    <a href="/">
                                        <img src={logo} alt="Logo" title="Logo"/>
                                    </a>
                                </div>
                            </div>

                            <div className="nav-outer clearfix d-flex align-items-center" style={{color: "white"}}>
                                <div className="menu-btn">
                                    <div className="office-time">
                                        <i className="far fa-clock"></i>
                                        <span>
                      &nbsp; <b>09:00 am - 11:00 pm</b>
                    </span>
                                    </div>
                                </div>
                            </div>

                            <div className="nav-outer clearfix d-flex align-items-center" style={{color: "white"}}>
                                <div className="menu-btn">
                                    Email: &nbsp;
                                    <a href="mailto:info@dilimatrix.com" style={{color: "white"}}>
                                        <b>info@dilimatrix.com</b>
                                    </a>
                                </div>
                            </div>

                            {/* <div className="nav-outer clearfix d-flex align-items-center">
                <Navigation className="nav-home-one" />
                <div className="menu-btn">
                  <a href="/contact" className="theme-btn">
                    Contact Us
                  </a>
                </div>
              </div> */}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default HeaderHomeOne;
