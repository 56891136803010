import React, {useEffect} from "react";
// import logo2 from "../../../../assets/images/logos/logo-two.png";
import logo from "../../../../assets/images/logos/dilimatrix-banner-white.png";
import StickyMenu from "../../../../lib/StickyMenu";
import Navigation from "../../../helpers/Navigation";

export default function HeaderHomeThree() {
    useEffect(() => {
        StickyMenu(".main-header");
    });
    return (
        <>
            {/* <MobileHeaderCom logo={logo2} /> */}
            <header className="main-header header-three text-white">
                <div className="header-upper">
                    <div className="container clearfix">
                        <div className="header-inner d-flex align-items-center">
                            <div className="logo-outer">
                                <div className="logo">
                                    <a href="/">
                                         <img src={logo} alt="Logo" title="Logo" style={{width: "170px"}} />
                                    </a>
                                </div>
                            </div>

                            <div className="nav-outer clearfix d-flex align-items-center">
                                <div className="main-menu navbar-expand-lg">
                                    <Navigation/>
                                </div>
                                <div className="header-top-wrap py-10">
                                    <div className="container">
                                        <div className="header-top">
                                            <div className="top-left">
                                                <ul>
                                                    {/* <li>
                            Call Us: <a href="callto:548978478">+94123456789</a>
                          </li> */}
                                                    <li>
                                                        Email: &nbsp;
                                                        <a href="mailto:info@dilimatrix.com">
                                                            info@dilimatrix.com
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="top-right">
                                                <div className="office-time">
                                                    <i className="far fa-clock"></i>
                                                    <span>09:00 am - 11:00 pm</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-btn">
                                    <a href="/contact" className="theme-btn">
                                        Contact Us
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
