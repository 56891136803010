import React from "react";

function ServiceSection() {
    return (
        <section className="services-section pt-120 rpt-100 pb-90 rpb-70">
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-sm-6">
                        <div className="service-box wow fadeInUp delay-0-2s">
                            <div className="service-normal">
                                <div className="icon">
                                    <i className="flaticon flaticon-computer"></i>
                                </div>
                                <h6>Web Design and Development</h6>
                                <p>
                                    We craft engaging websites that not only represent your brand
                                    effectively but also provide an exceptional user experience.
                                </p>
                                <a className="btn-circle" href="/service-details">
                                    <i className="fas fa-long-arrow-alt-right"></i>
                                </a>
                            </div>
                            <div className="service-hover bg-blue text-white">
                                <h4>Captivating Websites That Make an Impact</h4>
                                <p>
                                    Our web design and development services combine stunning
                                    aesthetics with seamless functionality. We craft engaging
                                    websites that not only represent your brand effectively but
                                    also provide an exceptional user experience.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <div className="service-box wow fadeInUp delay-0-4s">
                            <div className="service-normal">
                                <div className="icon">
                                    <i className="flaticon flaticon-web-development-4"></i>
                                </div>
                                <h6>Custom Software Development</h6>
                                <p>
                                    Our team of experienced developers specializes in creating
                                    custom software solutions that are designed to address your
                                    specific business challenges.
                                </p>
                                <a className="btn-circle" href="/service-details">
                                    <i className="fas fa-long-arrow-alt-right"></i>
                                </a>
                            </div>
                            <div className="service-hover bg-blue text-white">
                                <h4>Solutions for Your Business Needs</h4>
                                <p>
                                    We work closely with you to understand your requirements,
                                    goals, and processes, ensuring that the software we develop
                                    aligns perfectly with your organization. We leverage the
                                    latest technologies to deliver scalable, secure, and enhances
                                    efficiency.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <div className="service-box wow fadeInUp delay-0-6s">
                            <div className="service-normal">
                                <div className="icon">
                                    <i className="flaticon flaticon-web"></i>
                                </div>
                                <h6>Data Analytics and Business Intelligence</h6>
                                <p>
                                    Data is a valuable asset that can drive strategic
                                    decision-making and unlock insights that lead to business
                                    growth.
                                </p>
                                <a className="btn-circle" href="/service-details">
                                    <i className="fas fa-long-arrow-alt-right"></i>
                                </a>
                            </div>
                            <div className="service-hover bg-blue text-white">
                                <h4>Unlock the Power of Data</h4>
                                <p>
                                    We employ advanced analytics tools and techniques to collect,
                                    analyze, and visualize data, providing actionable insights
                                    that enhance operational efficiency, identify trends, and
                                    uncover new opportunities.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <div className="service-box wow fadeInUp delay-0-6s">
                            <div className="service-normal">
                                <div className="icon">
                                    <i className="flaticon flaticon-web"></i>
                                </div>
                                <h6>IT Consulting and Strategy Management</h6>
                                <p>
                                    We bring extensive industry knowledge and expertise by
                                    offering strategic recommendations, technology adoption and
                                    digital transformation.
                                </p>
                                <a className="btn-circle" href="/service-details">
                                    <i className="fas fa-long-arrow-alt-right"></i>
                                </a>
                            </div>
                            <div className="service-hover bg-blue text-white">
                                <h4>Preparing For Your Business With IT</h4>
                                <p>
                                    Our IT consulting and strategy services help businesses
                                    navigate the ever-changing technology landscape and align
                                    their IT initiatives with their overall objectives. We guide
                                    you on the path to success, leveraging technology as a key
                                    enabler.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;
