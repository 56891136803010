import React from "react";

export default function CallToAction() {
    return (
        <section className="call-to-action bg-blue pt-40 pb-15">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-9 col-lg-11">
                        <div className="section-title-with-btn">
                            <div className="section-title text-white mb-15">
                                <h2>Ready to get our professionasl solutions</h2>
                            </div>
                            <a href="service-one" className="theme-btn style-two mb-25">
                                View All Services
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
