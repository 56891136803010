import React from "react";
import Background from "../../assets/images/counter/counter-bg.jpg";

function AboutSection({className, funFact = true}) {
    return (
        <section
            className={`about-section bg-light-black pt-120 rpt-100 ${
                className || ""
            }`}
            id="about-section "
        >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-image-shape rmb-70 wow fadeInLeft delay-0-2s">
                            <img
                                src={require(`../../assets/images/about/about.png`).default}
                                // src="https://react.themepul.com/static/media/about.a8c17a64.png"
                                // src="https://cdn.pixabay.com/photo/2017/08/12/09/17/industry-2633879_1280.png"
                                alt="About"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content text-white pr-70 rpr-0 wow fadeInRight delay-0-2s">
                            <div className="section-title mb-35">
                                <span className="sub-title">It Support For Business</span>
                                <h2>
                                    Preparing for your success trusted source in IT services.
                                </h2>
                            </div>
                            <p>
                                Your success is our priority. As a trusted source in IT
                                services, we're dedicated to equipping you with innovative,
                                reliable, and efficient solutions. We believe in not only
                                meeting your digital needs but also in fostering a partnership
                                that helps you thrive in today's technology-driven world.
                                Prepare for your success with us, where your IT needs are met
                                with precision and expertise.
                            </p>
                            <ul className="list-style-one mt-15">
                                <li>Custom Software Solutions</li>
                                <li>Digital Presence Creation</li>
                                <li>Cloud-Based Infrastructure</li>
                                <li>Information Security Management</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {funFact && (
                    <div
                        className="fact-counter-inner br-5 px-25 pt-80 pb-30 text-white text-center"
                        style={{backgroundImage: `url(${Background})`}}
                    >
                        {/* <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-2s">
                  <span
                    className="count-text"
                    data-speed="5000"
                    data-stop="520"
                  >
                    <CounterUp endValue={520} sectionSelect="about-section " />
                  </span>
                  <p>Projects Done</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-4s">
                  <span className="count-text plus">
                    <CounterUp endValue={24} sectionSelect="about-section " />
                  </span>
                  <p>Awards Won</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-6s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="12"
                  >
                    <CounterUp endValue={12} sectionSelect="about-section " />
                  </span>
                  <p>Years Experience</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-8s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="352"
                  >
                    <CounterUp endValue={352} sectionSelect="about-section " />
                  </span>
                  <p>Happy Clients</p>
                </div>
              </div>
            </div> */}
                    </div>
                )}
            </div>
        </section>
    );
}

export default AboutSection;
