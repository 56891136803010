import React from "react";

function FeatureSection() {
    return (
        <section className="featured-section bgs-cover pt-120 rpt-150 pb-120 rpb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 col-sm-10">
                        <div className="section-title text-center mb-35">
                            <span className="sub-title">WHO WE ARE</span>
                            <h2>We deal with the aspects of professional IT Services</h2>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-xl-4 col-md-6">
                        <div className="feature-item wow fadeInUp delay-0-2s">
                            <div className="icon">
                                <i className="flaticon flaticon-art"></i>
                            </div>
                            <div className="feature-content">
                                <h5>Software Development</h5>
                                <p>
                                    We create customized software solutions tailored to your
                                    business needs.
                                </p>
                                {/* <a href="service-details" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="feature-item wow fadeInUp delay-0-4s">
                            <div className="icon">
                                <i className="flaticon flaticon-cloud-computing-1"></i>
                            </div>
                            <div className="feature-content">
                                <h5>Web Development</h5>
                                <p>
                                    We design and develop visually appealing, user-friendly
                                    websites optimized for performance.
                                </p>
                                {/* <a href="/service-details" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="feature-item wow fadeInUp delay-0-6s">
                            <div className="icon">
                                <i className="flaticon flaticon-development-3"></i>
                            </div>
                            <div className="feature-content">
                                <h5>Mobile App Development</h5>
                                <p>
                                    We build innovative mobile applications for iOS and Android
                                    platforms.
                                </p>
                                {/* <a href="/service-details" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="feature-item wow fadeInUp delay-0-8s">
                            <div className="icon">
                                <i className="flaticon flaticon-analysis-1"></i>
                            </div>
                            <div className="feature-content">
                                <h5>Cloud Computing</h5>
                                <p>
                                    We leverage cloud technologies for scalability,
                                    cost-efficiency, and seamless data access and collaboration.
                                </p>
                                {/* <a href="/service-details" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="feature-item wow fadeInUp delay-1-0s">
                            <div className="icon">
                                <i className="flaticon flaticon-web-development"></i>
                            </div>
                            <div className="feature-content">
                                <h5>IT Consulting</h5>
                                <p>
                                    We offer strategic guidance on technology adoption and
                                    optimizing IT infrastructure.
                                </p>
                                {/* <a href="/service-details" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="feature-item wow fadeInUp delay-1-2s">
                            <div className="icon">
                                <i className="flaticon flaticon-plan"></i>
                            </div>
                            <div className="feature-content">
                                <h5>E-commerce Solutions</h5>
                                <p>
                                    We build and optimize e-commerce platforms for seamless online
                                    shopping experiences.
                                </p>
                                {/* <a href="/service-details" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="feature-btn text-center mt-20">
          <a href="/service-one" className="theme-btn">
            view all services
          </a>
        </div> */}
            </div>
        </section>
    );
}

export default FeatureSection;
