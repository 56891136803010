import React from "react";

export default function PricingSectionFive() {
    return (
        <section className="pricing-section-six text-center pt-115 rpt-95 pb-90 rpb-70">
            <div className="container">
                <div className="section-title mb-100 rmb-55">
                    <span className="sub-title">Pricing Plan</span>
                    <h2>
                        We give a great assessing plan <br/>
                        to the application
                    </h2>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-6">
                        <div className="pricing-item-six wow fadeInUp delay-0-2s">
                            <h5 className="pricing-title">Basic Plan</h5>
                            <span className="price">19.23</span>
                            <span className="save-up">Save Up to 60%</span>
                            <p>
                                For people and little <br/>
                                activities
                            </p>
                            <a className="theme-btn" href="#">
                                Choose Now <i className="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 order-lg-3">
                        <div className="pricing-item-six wow fadeInUp delay-0-4s">
                            <h5 className="pricing-title">Basic Plan</h5>
                            <span className="price">19.23</span>
                            <span className="save-up">Save Up to 60%</span>
                            <p>
                                For people and little <br/>
                                activities
                            </p>
                            <a className="theme-btn" href="#">
                                Choose Now <i className="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="pricing-item-six wow fadeInUp delay-0-6s popular-plan rmt-0">
                            <h5 className="pricing-title">Basic Plan</h5>
                            <span className="price">19.23</span>
                            <span className="save-up">Save Up to 60%</span>
                            <p>
                                For people and little <br/>
                                activities
                            </p>
                            <a className="theme-btn" href="#">
                                Choose Now <i className="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
